<template>
  <!--Toolbar-->
  <div class="mb-6">
    <div class="flex justify-between flex-wrap md:gap-4">
      <!--Import-->
      <div class="flex gap-4">
        <div>
          <input type="file" ref="fileInput" class="hidden" accept=".xlsx, .xls" @change="handleClick">
          <button
            @click="$refs.fileInput.click()"
            class="btn">Importar desde excel
          </button>
        </div>
        <button @click="exportToExcel" class="btn btn--outline">Exportar ejemplo de formato</button>
      </div>
      <!--End-->
      <div class="flex justify-between items-center gap-4">
        <!---Search-->
        <div @focusout="showSearch= false" @click="showSearch= true" class="search">
          <svg class="search__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
               fill="none"
               stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <circle cx="11" cy="11" r="8"></circle>
            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
          </svg>
          <input id="search"
                 name="search"
                 @focusout="showSearch= false"
                 v-model="search"
                 class="input"
                 :class="{'show' : showSearch}" type="text"
                 placeholder="Buscar por nombre">
        </div>
        <!--End-->
        <!--Hide form-->
        <div @click="$emit('toggleForm')" class="float-right flex gap-2 cursor-pointer">
          <span class="text-sm underline">{{ showForm ? 'Ocultar' : 'Mostrar' }} formulario</span>
          <arrow-top-icon class="h-4 transition-all duration-300" :class="{'rotateArrow': !showForm}"></arrow-top-icon>
        </div>
        <!--End-->
      </div>
    </div>
  </div>
  <!--End-->
</template>

<script>
import ArrowTopIcon from '../atoms/icons/ArrowTopIcon.vue'
import XLSX from 'xlsx'
/* eslint-disable */
require('script-loader!file-saver')

import moment from 'moment'

export default {
  name: 'TableToolbar',
  components: { ArrowTopIcon },
  props: ['showForm', 'district'],
  data () {
    return {
      showSearch: false,
      search: '',
      excelData: {}
    }
  },
  watch: {
    search (value) {
      this.$emit('triggerSearch', value)
    }
  },
  methods: {
    /**
     * Exports to excel
     */
    async exportToExcel () {
      try {
        import('@/vendor/Export2Excel').then(excel => {
          const list = [
            {
              type: 'Programado',
              pickupAddress: 'Dirección de recojo',
              address: 'Dirección de entrega',
              reference: 'Cruce con santa cruz',
              district: this.district.label,
              fullName: 'José Ramirez',
              phone: '987676234',
              product: 'Hoodie y Poleras',
              quantity: 2,
              totalToBeCollected: 0,
              notes: 'Dejar en recepción',
              location: 'https://maps.app.goo.gl/unZzWJckbA7eMtkR6'
            },
            {
              type: 'Programado',
              pickupAddress: 'Dirección de recojo',
              address: 'Dirección de entrega',
              reference: 'Cruce con santa cruz',
              district: this.district.label,
              fullName: 'Oswaldo Ocampo',
              phone: '967987622',
              product: 'Productos de Skin Care',
              quantity: 3,
              totalToBeCollected: 0,
              notes: 'Departamento 401',
              location: 'https://maps.app.goo.gl/unZzWJckbA7eMtkR6'
            },
            {
              type: 'Programado',
              pickupAddress: 'Dirección de recojo',
              address: 'Dirección de entrega',
              reference: 'Cruce con santa cruz',
              district: this.district.label,
              fullName: 'Felipe Ruiz',
              phone: '967987622',
              product: 'Cuadros',
              quantity: 2,
              totalToBeCollected: 0,
              notes: 'Departamento 1102',
              location: 'https://maps.app.goo.gl/unZzWJckbA7eMtkR6'
            }
          ]
          const headerVal = [ 'type', 'pickupAddress', 'address', 'reference', 'district', 'fullName', 'phone', 'product', 'quantity', 'totalToBeCollected', 'notes', 'location']
          const headerTitle = ['Tipo de servicio', 'Local de recojo (Direccion de recojo)', 'Dirección (Direccion de entrega)', 'Referencia', 'Distrito', 'Nombre de cliente', 'Teléfono', 'Producto', 'Cantidad', 'Total a cobrar', 'Notas de entrega', 'Ubicación']
          const data = this.formatJson(headerVal, list)
          excel.export_json_to_excel({
            header: headerTitle,
            data,
            filename: 'Template para importación',
            autoWidth: true,
            bookType: 'xlsx'
          })
        })
      } catch (e) {
        this.$toast.error('Error al exportar', {
          duration: 6000
        })
      }
    },
    /**
     * Format json
     * @param filterVal
     * @param jsonData
     * @returns {*}
     */
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    },
    handleClick (e) {
      const files = e.target.files
      const rawFile = files[0]
      if (!rawFile) return
      this.uploadFile(rawFile)
    },
    uploadFile (file) {
      this.$refs.fileInput.value = null // fix can't select the same excel
      this.readerData(file)
    },
    readerData (rawFile) {
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onload = e => {
          const data = e.target.result
          const workbook = XLSX.read(data, { type: 'array' })
          const firstSheetName = workbook.SheetNames[0]
          const worksheet = workbook.Sheets[firstSheetName]
          const header = this.getHeaderRow(worksheet)
          const results = XLSX.utils.sheet_to_json(worksheet)
          const meta = { sheetName: firstSheetName }
          this.generateData({
            header,
            results,
            meta
          })
          resolve()
        }
        reader.readAsArrayBuffer(rawFile)
      })
    },
    generateData ({
      header,
      results,
      meta
    }) {
      this.excelData.header = header
      this.excelData.results = results
      this.excelData.meta = meta
      this.$emit('done', results)
    },
    getHeaderRow (sheet) {
      const headers = []
      const range = XLSX.utils.decode_range(sheet['!ref'])
      let C
      const R = range.s.r
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) { /* walk every column in the range */
        const cell = sheet[XLSX.utils.encode_cell({
          c: C,
          r: R
        })]
        /* find the cell in the first row */
        let hdr = `UNKNOWN ${C}` // <-- replace with your desired default
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell)
        headers.push(hdr)
      }
      return headers
    }
  }
}
</script>

<style>
.rotateArrow {
  rotate: 180deg;
}
</style>
